// These are the styles to make some of the templates look extra nice.
// Feel free to remove these styles for production!

// Body Styles 
body,
html, 
.body {
  background: #f3f3f3 !important;
}

.container.header {
  background: $primary-color;

  .columns {
    padding-bottom: 16px;
    padding-top: 16px;
  }
}

.header .columns {
  padding-bottom: 0;
}

.header p {
  color: #fff;
  padding-top: 15px;
  margin-bottom: 0;
}

.header .wrapper-inner {
  padding: 20px;
}

.container.sub-header {
  background: $black;

  p {
    padding-top: 25px;
    color: $dark-gray;
  }

  .container {
    background: transparent;
  }
}

.sidebar-item {
  padding-left: 0 !important;
}

.footer {
  background-color: $light-gray;

  .container {
    background: transparent;
  }
}


// Social Media
table.button.facebook table td {
  background: #3B5998 !important;
  border-color: #3B5998;
}

table.button.twitter table td {
  background: #1daced !important;
  border-color: #1daced;
}

table.button.google table td {
  background: #DB4A39 !important;
  border-color: #DB4A39;
}

.wrapper.secondary {
  background: #f3f3f3;
}